<template>
  <div>
    <!--内容区域-->
    <el-row>
      <!--熊猫图标-->
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <!--title-->
      <el-col :span="22">
        <p class="title">Organisation Committee</p>
      </el-col>
    </el-row>
    <!--人员信息-->
    <div class="pre">
      <el-row v-for="(itme,index) in personnel" :key="index">
        <el-col class="personnels">
          <p class="headset">{{ itme.title }}</p>
          <!-- <p class="email">{{ itme.email}}</p> -->
          <div class="parent clearfix">
            <div class="personnel" v-for="(pre ,index) in itme.context" :key="index">
              <div class="pre-header">
                <div>
                  <img :src="pre.image" alt="">
                </div>
              </div>
              <div class="pre-middle">
                <p>{{ pre.name }}</p>
              </div>
              <div class="pre-button">
                <p>{{ pre.address }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Organizing Committee",
  mounted() {
    document.title = 'ACM Multimedia 2024 Organizing Committee'
  },
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      personnel: [
        {
          title: 'Honorary Chairs',
          context: [
            {
              image: require('@/assets/organization/svetha_venkatesh.webp'),
              name: 'Svetha Venkatesh',
              address: 'Deakin University, Australia',
            },
            {
              image: require('@/assets/organization/klara_nahrstedt.png'),
              name: 'Klara Nahrstedt',
              address: 'University of Illinois Urbana-Champaign, USA',
            },
            {
              image:require('@/assets/organization/dame_wendy_hall.jpg'),
              name: 'Dame Wendy Hall',
              address: 'University of Southampton, UK'
            }
          ],
        },
        {
          title: 'General Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Jianfei_Cai_Monash.jpg'),
              name: 'Jianfei Cai',
              address:
                  'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Mohan_kankankalli.jpg'),
              name: 'Mohan Kankanhalli',
              address: 'National University of Singapore',
            },
            {
              image: require('@/assets/organization/Balakrishnan_Prabhakaran.jpg'),
              name: 'Balakrishnan Prabhakaran',
              address: 'University of Texas at Dallas, USA',
            },
            {
              image: require('@/assets/organization/Susanne_Boll.png'),
              name: 'Susanne Boll',
              address: 'University of Oldenburg, Germany',
            },
          ],
        },
        {
          title: 'Program Co-ordinator',
           email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Dong_Xu.png'),
              name: 'Dong Xu',
              address:
                  'The University of Hong Kong',
            }
          ],
        },
        {
          title: 'Technical Program Chairs',
           email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Ramanathan_Subramanian.jpeg'),
              name: 'Ramanathan Subramanian',
              address:
                  'University of Canberra/ IIT Ropar (India)',
            },
            {
              image: require('@/assets/organization/Vivek_Singh.jpg'),
              name: 'Vivek K. Singh',
              address: 'Rutgers University, USA',
            },
            {
              image: require('@/assets/organization/Lexing_Xie.jpg'),
              name: 'Lexing Xie',
              address: 'Australian National University',
            },
            {
              image: require('@/assets/organization/Pablo_Cesar.jpg'),
              name: 'Pablo Cesar',
              address: 'Centrum Wiskunde & Informatica (CWI), Netherlands',
            },
            {
              image: require('@/assets/organization/Liang_Zheng.jpg'),
              name: 'Liang Zheng',
              address: 'Australian National University',
            },
          ],
        },
        {
          title: 'Industry Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            // - Jianquan Liu, NEC Corporation, Japan
            // - Balu Adsumilli, YouTube, USA
            // - Yukiko Yanagawa, Omron Corporation, Japan
            // - Haiwei Dong, Huawei Canada
            {
              image: require('@/assets/organization/Jianquan_Liu.png'),
              name: 'Jianquan Liu',
              address: 'NEC Corporation, Japan',
            },
            {
              image: require('@/assets/organization/Balu_Adsumilli.jpg'),
              name: 'Balu Adsumilli',
              address: 'YouTube, USA',
            },
            {
              image: require('@/assets/organization/Yukiko_Yanagawa.jpeg'),
              name: 'Yukiko Yanagawa',
              address: 'Omron Corporation, Japan',
            },
            {
              image: require('@/assets/organization/Haiwei_Dong.jpg'),
              name: 'Haiwei Dong',
              address: 'Huawei Canada',
            },
          ],
        },
        {
          title: 'Panel Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Helen_Huang.jpeg'),
              name: 'Helen Zi Huang',
              address: 'The University of Queensland, Australia',
            },
            {
              image: require('@/assets/organization/Phoebe_Chen.jpeg'),
              name: 'Phoebe Chen',
              address: 'La Trobe University, Australia',
            },
            {
              image: require('@/assets/organization/Shuicheng_YAN.jpeg'),
              name: 'Shuicheng YAN',
              address: 'Beijing Academy of Artificial Intelligence (BAAI)',
            },
          ],
        },
        {
          title: 'Awards Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Jiebo_Luo.jpeg'),
              name: 'Jiebo Luo',
              address: 'University of Rochester, USA',
            },
            {
              image: require('@/assets/organization/Dinh_Phung.png'),
              name: 'Dinh Phung',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Niculae_Sebe.jpg'),
              name: 'Niculae Sebe',
              address: 'University of Trento, Italy',
            },
          ],
        },
        {
          title: 'Workshop Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Zhiyong_Wang.jpeg'),
              name: 'Zhiyong Wang',
              address: 'University of Sydney, Australia',
            },
            {
              image: require('@/assets/organization/Jiaying_Liu.jpeg'),
              name: 'Jiaying Liu',
              address:
                  'Peking University, China',
            },
            {
              image: require('@/assets/organization/Tatsuya_Harada.jpg'),
              name: 'Tatsuya Harada',
              address: 'University of Tokyo, Japan',
            },
          ],
        },
        {
          title: 'Tutorial Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Jungong_Han.jpg'),
              name: 'Jungong Han',
              address: 'University of Sheffield, UK',
            },
            {
              image: require('@/assets/organization/Jingjing_Meng.jpeg'),
              name: 'Jingjing Meng',
              address: 'University at Buffalo, USA',
            },
            {
              image: require('@/assets/organization/Roland_Goecke.jpeg'),
              name: 'Roland Goecke',
              address: 'University of Canberra, Australia',
            },
          ],
        },
        
        {
          title: 'Brave New Idea Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Tat-Seng_Chua.jpeg'),
              name: 'Tat-Seng Chua',
              address: 'National University of Singapore',
            },
            {
              image: require('@/assets/organization/Chen_Change_Loy.jpeg'),
              name: 'Chen Change Loy',
              address: 'Nanyang Technological University, Singapore',
            },
            {
              image: require('@/assets/organization/Marcel_Worring.jpeg'),
              name: 'Marcel Worring',
              address: 'University of Amsterdam, Netherlands',
            },
          ],
        },
        {
          title: 'Grand Challenge Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Hanwang_Zhang.jpeg'),
              name: 'Hanwang Zhang',
              address: 'Nanyang Technological University, Singapore',
            },
            {
              image: require('@/assets/organization/Elisa_Ricci.jpeg'),
              name: 'Elisa Ricci',
              address: 'University of Trento, Italy',
            },
            {
              image: require('@/assets/organization/si_liu.jpeg'),
              name: 'Si Liu',
              address: 'Beihang University, China',
            },
            {
              image: require('@/assets/organization/Wanli_Ouyang.jpg'),
              name: 'Wanli Ouyang',
              address: 'Shanghai AI Lab',
            },
          ],
        },
        {
          title: 'Exhibits/Demo/Video Program Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Abhinav_Dhall.jpeg'),
              name: 'Abhinav Dhall',
              address: 'Indian Institute of Technology Ropar',
            },
            {
              image: require('@/assets/organization/Qianru_Sun.jpeg'),
              name: 'Qianru Sun',
              address: 'Singapore Management University',
            },
            {
              image: require('@/assets/organization/Chi-Wing_Fu_Philip.jpg'),
              name: 'Chi-Wing Fu, Philip',
              address: 'Chinese University of Hong Kong',
            },
          ],
        },
        {
          title: 'Publicity Chairs',
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Joanna_Batstone.jpeg'),
              name: 'Joanna L Batstone',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Heng_Tao_SHEN.jpeg'),
              name: 'Heng Tao SHEN',
              address: 'University of Electronic Science and Technology of China',
            },
            {
              image: require('@/assets/organization/Junsong_Yuan.jpeg'),
              name: 'Junsong Yuan',
              address: 'State University of New York at Buffalo, USA',
            },
            {
              image: require('@/assets/organization/Sanghoon_Lee.jpg'),
              name: 'Sanghoon Lee',
              address: 'Yonsei University, Korea',
            },
            {
              image: require('@/assets/organization/Yonggang_Wen.jpeg'),
              name: 'Yonggang Wen',
              address: 'Nanyang Technological University, Singapore',
            },
          ],
        },
        {
          title: "Doctoral Symposium Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Stefan_Winkler.jpeg'),
              name: 'Stefan Winkler',
              address: 'National University of Singapore',
            },
            {
              image: require('@/assets/organization/Ajmal_Saeed_Mian.jpeg'),
              name: 'Ajmal Saeed Mian',
              address: 'University of Western Australia',
            },
            {
              image: require('@/assets/organization/Qin_Jin.jpg'),
              name: 'Qin Jin',
              address: 'Renmin University of China',
            },
            {
              image: require('@/assets/organization/Gholamreza_(Reza)_Haffari.jpeg'),
              name: 'Gholamreza (Reza) Haffari',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Changsheng_Xu.jpeg'),
              name: 'Changsheng Xu',
              address: 'Chinese Academy of Sciences',
            },
          ]
        },
        {
          title: "Sponsorship Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Wei_Xiang.jpeg'),
              name: 'Wei Xiang',
              address: 'La Trobe University, Australia',
            },
            {
              image: require('@/assets/organization/Changhu_Wang.jpeg'),
              name: 'Changhu Wang',
              address: 'AIsphere',
            },
          ]
        },
        {
          title: "Local Arrangement Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Yuan-Fang_Li.jpeg'),
              name: 'Yuan-Fang Li',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Yasmeen_M_George.jpg'),
              name: 'Yasmeen M. George',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Jian_Zhang.jpeg'),
              name: 'Jian Zhang',
              address: 'University of Technology Sydney, Australia',
            },
          ]
        },

        {
          title: "Social Media & Web Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/hengcan_shi.jpeg'),
              name: 'Hengcan Shi',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Qian_Yu.jpeg'),
              name: 'Qian Yu',
              address: 'Beihang University, China',
            },
            // {
            //   image: require('@/assets/organization/Hamid_Rezatofighi.jpeg'),
            //   name: 'Hamid Rezatofighi',
            //   address: 'Monash University, Australia',
            // },
          ]
        },

        {
          title: "Student & Young Professionals Activities",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Min_Xu.jpeg'),
              name: 'Min Xu',
              address: 'University of Technology Sydney, Australia',
            },
            {
              image: require('@/assets/organization/Wei_Qi_Yan.jpeg'),
              name: 'Wei Qi Yan',
              address: 'Auckland University of Technology, New Zealand',
            },
          ]
        },

        {
          title: "Open Source Software Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Guosheng_Lin.jpeg'),
              name: 'Guosheng Lin',
              address: 'Nanyang Technological University, Singapore',
            },
            {
              image: require('@/assets/organization/Yunchao_Wei.jpeg'),
              name: 'Yunchao Wei',
              address: 'Beijing Jiaotong University, China',
            },
          ]
        },

        {
          title: "Reproducible Companion Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/bohan_zhuang.jpeg'),
              name: 'Bohan Zhuang',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/YANG_YINPING.jpg'),
              name: 'Yang Yingping',
              address: 'IHPC (A*STAR), Singapore',
            },
          ]
        },

        // {
        //   title: "Interactive Art Chairs",
        //   email: 'acmmm24@icmsaust.com.au',
        //   context: [
        //     {
        //       image: require('@/assets/organization/Juyong_Zhang.jpeg'),
        //       name: 'Juyong Zhang',
        //       address: 'University of Science and Technology of China',
        //     },
        //     {
        //       image: require('@/assets/organization/Chi-Wing_Fu_Philip.jpg'),
        //       name: 'Chi-Wing Fu, Philip',
        //       address: 'Chinese University of Hong Kong',
        //     },
        //   ]
        // },
        {
          title: "Publication Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Toan_Do.png'),
              name: 'Toan Do',
              address: 'Monash University, Australia',
            },
            {
              image: require('@/assets/organization/Yadan_Luo.jpeg'),
              name: 'Yadan Luo',
              address: 'University of Queensland, Australia',
            },
            {
              image: require('@/assets/organization/Yan_Yan.jpeg'),
              name: 'Yan Yan',
              address: 'Illinois Institute of Technology, USA',
            },
          ]
        },
        {
          title: "Author’s Advocate Chairs",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Joao_Magalhaes.jpg'),
              name: 'Joao Magalhaes',
              address: 'NOVA University of Lisbon, Portugal',
            },
          ]
        },
        {
          title: "Finance Chairs/Registration",
          email: 'acmmm24@icmsaust.com.au',
          context: [
            {
              image: require('@/assets/organization/Qiuhong_Ke.png'),
              name: 'Qiuhong Ke',
              address: 'Monash University, Australia',
            },
          ]
        },
      ]
    }
  }

}
</script>

<style scoped>
@import "../../css/responsive.css";

.headset {
  font-size: 2.8rem !important;
}
.email {
  font-size: 1.7rem !important;
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
  color: #6E6E6E;
  line-height: 20px;
}

p {
  margin: 0 0 0 !important;
}

.parent {
  /* display: inline-block; */
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-content: center;
  align-items: center;
}

.personnel {
  float: left;
  width: 18rem;
  height: 28rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  /* margin: 0 auto; */
  /* padding: 10px; */
  position: relative;
}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 16rem;
  height: 16rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
 
  /* min-height: 16rem;
  min-width: 16rem; */
}

.pre-header > div > img {
  align-content: center;
  width: 100%;
  height: 16rem;
  border-radius: 8rem;
  /* min-height: 16rem;
  min-width: 16rem; */
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 1.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 1rem;
}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;
  color: #0054B0;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }
  /* .parent .personnel:last-child {
    margin-left: 1rem;
  } */

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}

</style>
